import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { LoginLanding } from './components/LoginLanding';
import { Header } from './components/organisms/Header';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/pb" />
                </Route>
                <Route exact path="/pb">
                    <Header />
                    <LoginLanding />
                </Route>
                <Route exact path="/landing">
                    <LoginLanding hidePrivateCompanySelection />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
