import { createMuiTheme } from '@material-ui/core/styles';

// EB Light Theme
export const lightTheme = createMuiTheme(
    {
        palette: {
            primary: {
                light: '#82c0f440',
                main: '#3a41ff',
                dark: '#2c31bf',
            },
            secondary: {
                /* light: '#d3fffa', */
                light: '#19bfac40',
                main: '#19bfac',
                dark: '#118073',
            },
            text: {
                primary: '#101820',
                secondary: '#40464D',
                disabled: '#0000008c',
            },
            background: {
                default: '#FFFFFF',
                paper: '#F7F7FF',
            },
            success: {
                main: '#21ac04',
                light: '#7acd68a3',
                dark: '#198103',
            },
            warning: {
                main: '#e17000',
                light: '#eda966a3',
                dark: '#a95400',
            },
            error: {
                main: '#cb0000',
                light: '#e06666a3',
                dark: '#980000',
            },
            info: {
                light: '#d3a7ffa3',
                main: '#1779FF',
                dark: '#0C3D80',
            },
        },
        typography: {
            fontFamily: "'Gotham-Book', 'Poppins', 'Arial'",
            fontSize: 16,
            h1: {
                fontFamily: "'Gotham-Medium', 'Poppins'",
                fontSize: '5rem',
                lineHeight: '125%',
            },
            h2: {
                fontFamily: "'Gotham-Medium', 'Poppins'",
                fontSize: '3rem',
                lineHeight: '125%',
            },
            h3: {
                fontFamily: "'Gotham-Medium', 'Poppins'",
                fontSize: '2.5rem',
                lineHeight: '125%',
            },
            h4: {
                fontFamily: "'Gotham-Medium', 'Poppins'",
                fontSize: '1.75rem',
            },
            h5: {
                fontFamily: "'Gotham-Medium', 'Poppins'",
                fontSize: '1.5rem',
                lineHeight: '150%',
            },
            h6: {
                fontFamily: "'Gotham-Medium', 'Poppins'",
                fontSize: '1.15rem',
                lineHeight: '150%',
            },
            button: {
                fontFamily: "'Gotham-Book', 'Poppins'",
                textTransform: 'none',
            },
            subtitle1: {
                fontFamily: "'Gotham-Bold', 'Poppins'",
                fontSize: '1.35rem',
                lineHeight: '150%',
            },
            subtitle2: {
                fontFamily: "'Gotham-Bold', 'Poppins'",
                fontSize: '1.35rem',
                lineHeight: '150%',
                textTransform: 'uppercase',
            },
            body1: {
                lineHeight: '150%',
            },
            body2: {
                lineHeight: '150%',
            },
            caption: {
                fontSize: '0.9rem',
                lineHeight: '150%',
            },
            overline: {
                fontFamily: 'Gotham-Book',
                lineHeight: '150%',
            },
        },
        shape: {
            borderRadius: 16,
        },
    }
);
