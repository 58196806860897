import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as Logo } from '../../assets/eb-logo-text.svg';

// material-ui
import { AppBar, Toolbar } from '@material-ui/core';

interface Props { }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            background: theme.palette.common.white,
            padding: theme.spacing(4),
        },
        title: {
            flexGrow: 1,
        },
    })
);

export const Header: React.FC<Props> = () => {
    const classes = useStyles();

    return (
        <AppBar position="static" elevation={0} classes={{ root: classes.appBar }}>
            <Toolbar disableGutters>
                <Link to="/">
                    <Logo height={40} width="auto" />
                    {/* <img src={PrideLogo} height={40} width="auto" alt='Eidsiva Pride Logo' /> */}
                </Link>
            </Toolbar>
        </AppBar>
    );
};
