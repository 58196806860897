import React from 'react';

interface Props {
    color?: string;
    width: number;
    height: number;
    className: string;
}

/**
 * ### A customizable EB Logo component ###
 * Use the color, background color, width and height to customize the EB logo
 * @example <caption>Usage of the logo with transparent background and responsive sizes</caption>
 * ```jsx
 * <EbLogo
 *      className={classes.logo}
 *      color={theme.palette.primary.main}
 *      backgroundColor="transparent"
 *      height={small ? 32 : 72}
 *      width={small ? 32 : 72}
 * />
 * ```
 *
 * @param color The color of the logo
 * @param backgroundColor The background color of the logo
 * @param width
 * @param height
 * @param className Apply a custom className
 */
export const EbLogo: React.FC<Props> = ({ color="#101820", width, height, className }) => {
    return (
        <svg className={className}
             width={width}
             height={height}
             viewBox="0 0 272 272"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M40.8694 90.1066C40.8694 83.3866 45.8771 78.605 52.371 78.605C58.8972 78.605 63.8726 83.3866 63.8726 90.1066C63.8726 96.8267 58.8649 101.608 52.371 101.608C45.8771 101.641 40.8694 96.859 40.8694 90.1066Z" fill={color}/>
            <path d="M124.482 222.763C124.482 229.483 129.49 234.264 135.984 234.264C142.51 234.264 147.485 229.483 147.485 222.763C147.485 216.043 142.477 211.261 135.984 211.261C129.49 211.261 124.482 216.01 124.482 222.763Z" fill={color}/>
            <path d="M115.63 141.864H160.086V128.327H115.63V96.6651H169.003V83.1281H101.059V188.872H170.941V175.335H115.63V141.864Z" fill={color}/>
            <path d="M219.596 188.743C226.123 188.743 231.098 183.961 231.098 177.241C231.098 170.521 226.123 165.739 219.596 165.739C213.07 165.739 208.095 170.521 208.095 177.241C208.095 183.961 213.102 188.743 219.596 188.743Z" fill={color}/>
        </svg>
    );
};
