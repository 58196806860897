import React, { useEffect, useState } from 'react';
import { ReactComponent as MyPage } from '../assets/illustrations/eb-minside.svg';
import axios from 'axios';
import { EbLoading } from './organisms/EbLoading';
import { validateEmail } from '../helpers/Validators';

// material ui
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Typography,
    CardActions,
    Button,
    TextField,
    Collapse,
    Box,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { LogEvent } from '../interfaces/ILog';

interface Props {
    setEmail: (email: string) => void;
    setIsMigrated: (value: boolean) => void;
    setSecondaryRedirectUrl: (value: string) => void;
    email: string;
    isMigrated: boolean;
}

interface IRedirectResponse {
    isMigrated: boolean;
    redirectUrl: string;
    hasNetadminId: boolean;
    hasCompletedMigration: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            padding: theme.spacing(2),
            textAlign: 'left',
            maxWidth: theme.breakpoints.values.sm,
        },
        customDisabled: {
            '& .Mui-disabled': {
                backgroundColor: theme.palette.success.main,
                color: 'white',
            },
        },
    })
);

export const LoginIntro: React.FC<Props> = ({
    setEmail,
    email,
    isMigrated,
}) => {
    const classes = useStyles();
    const [emailValid, setEmailValid] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailValid(validateEmail(event.target.value));
    };

    const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setEmailValid(validateEmail(event.target.value));
        setIsBlur(true);
    };

    const handleSubmit = (event: any) => {
        let logEvent: LogEvent = 'landing emailNext';
        axios.get(`log/event?eventName=${logEvent}`);

        if (!emailValid) return;
        setLoading(true);
        localStorage.setItem('eb.hasSetEmail', JSON.stringify(email));

        // set cookie for Ebias email autocomplete
        let expireDate = new Date();
        expireDate.setMinutes(expireDate.getMinutes() + 120);
        let cookie = `username=${email}; expires=${expireDate.toUTCString()}; Domain=.eidsiva.net`;
        document.cookie = cookie;

        axios
            .get(`/customers/customer-migration-info?email=${encodeURIComponent(email)}`)
            .then((response) => {
                const redirectResponse: IRedirectResponse = response.data;
                const { redirectUrl } = redirectResponse;
                window.location.href = redirectUrl;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const handleKeyPress = (event: any) => {
        if (event.key == 'Enter') {
            handleSubmit(null);
        }
    };

    useEffect(() => {
        const hasSetEmail = localStorage.getItem('eb.hasSetEmail');
        if (hasSetEmail) {
            const email: string = JSON.parse(hasSetEmail);
            setEmailValid(validateEmail(email));
            setIsBlur(true);
        }
    }, []);

    return (
        <Card classes={{ root: classes.card }} elevation={0}>

            <CardContent>
                <Typography paragraph>
                    Hei. Vi trenger å verifisere at du er en av våre kunder. Skriv inn e-postadressen
                    du har registrert hos oss.
                </Typography>
                <TextField
                    classes={{ root: classes.customDisabled }}
                    disabled={loading || isMigrated}
                    variant="filled"
                    label="E-postadresse"
                    fullWidth
                    autoFocus={true}
                    color="primary"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    onKeyPress={handleKeyPress}
                    type="email"
                    helperText={
                        isBlur && !emailValid && email !== ''
                            ? 'Har du skrevet riktig e-postadresse?'
                            : null
                    }
                    error={isBlur && !emailValid && email !== ''}
                    InputProps={{
                        endAdornment: isMigrated ? <Done /> : null,
                    }}
                />
            </CardContent>

            <Collapse in={!isMigrated}>
                <CardActions style={{ height: 72 }}>
                    <Button
                        onClick={handleSubmit}
                        disabled={loading || isMigrated || !emailValid}
                        color="primary"
                        size="large"
                        variant="contained"
                        disableElevation
                        style={{ borderRadius: 24 }}
                    >
                        Gå videre
                    </Button>

                </CardActions>
                <Box mt={2}>
                    {loading && <EbLoading description="Verifiserer epost..." small />}
                </Box>
            </Collapse>
        </Card>
    );
};
