import React from 'react';
import { EbLogo } from '../atoms/EbLogo';

// material ui
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    CircularProgress,
    Box,
    Theme,
    fade,
    useTheme,
    Backdrop,
} from '@material-ui/core';

interface Props {
    description?: string;
    small?: boolean;
    backdrop?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
        },
        containerSmall: {
            display: 'flex',
            alignItems: 'center',
        },
        circularProgress: {
            position: 'absolute',
            animationDuration: '2s',
        },
        logo: {
            position: 'absolute',
        },
        description: {
            marginTop: theme.spacing(2),
        },
        backdrop: {
            color: '#FFF',
            backgroundColor: fade(theme.palette.grey[900], 0.75),
            backgroundImage: "url('/patterns/eb-pattern-black.svg')",
            backgroundSize: 'cover',
            backgroundBlendMode: 'multiply',
            zIndex: 'unset',
        },
    })
);

export const EbLoading: React.FC<Props> = ({ description, small, backdrop }) => {
    const classes = useStyles();
    const theme = useTheme();

    const Loading = () => (
        <div className={small ? classes.containerSmall : classes.container}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                marginTop={small ? 0 : 5}
                style={{ marginRight: small ? 16 : 0 }}
            >
                <CircularProgress
                    classes={{ root: classes.circularProgress }}
                    color="secondary"
                    thickness={2}
                    size={small ? 48 : 96}
                />
                <CircularProgress color="primary" thickness={2} size={small ? 56 : 112} />
                <EbLogo
                    className={classes.logo}
                    height={small ? 32 : 72}
                    width={small ? 32 : 72}
                />
            </Box>
            {description ? (
                <Typography align={small ? 'left' : 'center'} className={small ? '' : classes.description}>
                    {description}
                </Typography>
            ) : null}
        </div>
    );

    return backdrop ? (
        <Backdrop open style={{ zIndex: 10, color: '#FFF' }}>
            <Loading />
        </Backdrop>
    ) : (
        <Loading />
    );
};
