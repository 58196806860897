import React, { useState, useEffect } from 'react';
import { PageTitle } from '../components/molecules/PageTitle';
import { LoginIntro } from './LoginIntro';
import LoginIcon from '@material-ui/icons/VerifiedUser';
import { ReactComponent as Security } from '../assets/illustrations/sikkerhet.svg'

// material ui
import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Grow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

type mypageType = 'private' | 'company';

interface Props {
    hidePrivateCompanySelection?: boolean;
}

export const LoginLanding: React.FC<Props> = ({ hidePrivateCompanySelection = false }) => {
    const [email, setEmail] = useState('');
    const [mypage, setMypage] = useState<mypageType | null>(null);
    const [secondaryRedirectUrl, setSecondaryRedirectUrl] = useState('');
    const [isMigrated, setIsMigrated] = useState(false);
    const [count, setCount] = useState(8);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickPrivate = () => {
        setMypage('private');
    };

    const handleClickCompany = () => {
        window.location.href = 'https://minvirksomhet.eidsiva.net/';
    };

    useEffect(() => {
        if (count > 0) {
            setTimeout(() => setCount(count - 1), 1000);
        }
    }, [count]);

    return (
        <Container>


            {!hidePrivateCompanySelection ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Box display="flex" alignItems="center" marginBottom={4}>
                        <Security height={smDown ? 40 : 64} width="auto" />
                        <Typography variant='h1' style={{ fontSize: smDown ? '2.5rem' : '3.5rem', marginLeft: 32 }} >Innlogging</Typography>
                    </Box>
                    <Typography paragraph>Velg ditt kundeforhold:</Typography>
                    <ButtonGroup color="primary">
                        <Button
                            size="large"
                            onClick={handleClickPrivate}
                            variant={mypage === 'private' ? 'contained' : 'outlined'}
                        >
                            Privat
                        </Button>
                        <Button
                            size="large"
                            onClick={handleClickCompany}
                            variant={mypage === 'company' ? 'contained' : 'outlined'}
                        >
                            Bedrift
                        </Button>
                    </ButtonGroup>
                </Box>
            ) : null}

            <Grow in={mypage === 'private' || hidePrivateCompanySelection} timeout={{ enter: 600 }}>
                <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                    <LoginIntro
                        setEmail={setEmail}
                        setIsMigrated={setIsMigrated}
                        setSecondaryRedirectUrl={setSecondaryRedirectUrl}
                        email={email}
                        isMigrated={isMigrated}
                    />
                </Box>
            </Grow>
        </Container>
    );
};
